import { Avatar, Text, Group, Skeleton } from "@mantine/core";
import { IconPhoneCall, IconAt } from "@tabler/icons-react";
import classes from "./Assets.module.css";
import { Asset } from "../../app/types/asset";

type AssetCardProps = {
  asset: Asset;
};

export function AssetCard({ asset }: AssetCardProps) {
  return (
    <div>
      <Group wrap="nowrap">
        <Avatar size={94} radius="md" />
        <div>
          <Text fw={500} c="dimmed">
            {"Asset Ticker"}: {asset.ticker}
          </Text>

          <Text fw={500} className={classes.name}>
            Asset Name: {asset.name}
          </Text>

          <Text fw={500} className={classes.name}>
            Asset Type: {asset.type}
          </Text>

          <Group wrap="nowrap" gap={10} mt={3}>
            <IconAt stroke={1.5} size="1rem" className={classes.icon} />
            <Text fz="xs" c="dimmed">
              {/* robert@glassbreaker.io */}
            </Text>
          </Group>

          <Group wrap="nowrap" gap={10} mt={5}>
            {/* <IconPhoneCall stroke={1.5} size="1rem" className={classes.icon} /> */}
            <Text fz="xs" c="dimmed">
              {/* +11 (876) 890 56 23 */}
            </Text>
          </Group>
        </div>
      </Group>
    </div>
  );
}
