import { useContext, useEffect, useState } from "react";
import Context from "../../context/Context";
import {
  useAppDispatch,
  useAppSelector,
  gqlAssetByTiker,
} from "../../app/index";
import { Container, Divider, Grid, Group, Skeleton, Text } from "@mantine/core";
import { Link, useParams } from "react-router-dom";
import { AssetCard } from "./AssetCard";
import { Holders } from "./Holders";
import { Investments } from "./Investments";
import { AssetFundings } from "./Fundings";
import { AssetStos } from "./Stos";

const REACT_APP_SUBSCAN_URL = process.env.REACT_APP_SUBSCAN_URL;

export default function TokenDetails() {
  const [ticker, setTicker] = useState<string>("");
  const [value, setValue] = useState("");
  const context = useContext(Context);
  const dispatch = useAppDispatch();
  const params = useParams();

  const {
    response: _asset,
    loading,
    error,
  } = useAppSelector((state) => state.asset);

  useEffect(() => {
    setTicker(params.ticker || "");
    const _ticker = params.ticker || "";
    console.log("TokenDetails : ", _ticker);
    dispatch(gqlAssetByTiker({ tiker: _ticker }));
  }, [value]);

  if (loading) return <h1>Loading Asset Details ... </h1>;

  // const child = <Skeleton height={140} radius="md" animate={false} />;

  return (
    <>
      <Group>
        <Link to="/tokens">
          <Text size="lg">Tokens</Text>
        </Link>{" "}
        / <Text size="lg">{ticker}</Text>
      </Group>

      <Divider my="xs" />

      <Container size={"xl"} fluid={true}>
        <Grid>
          {/* Holders  */}
          <Grid.Col span={{ base: 12, xs: 12 }}>
            {_asset?.assets.nodes.map((asset) => (
              <AssetCard asset={asset} key={"1"} />
            ))}
          </Grid.Col>
          <Grid.Col span={{ base: 12, xs: 12 }}>
            {_asset?.assets.nodes.map((asset) => (
              <Holders holders={asset.holders} key={"1"} />
            ))}
          </Grid.Col>
          {/* Investments  */}
          <Grid.Col span={{ base: 12, xs: 12 }}>
            <Investments investments={_asset?.investments} />
          </Grid.Col>
          {/* Fundings ...  */}
          <Grid.Col span={{ base: 12, xs: 12 }}>
            <AssetFundings fundings={_asset?.fundings} key={"1"} />
          </Grid.Col>

          {/* Stos ...  */}
          <Grid.Col span={{ base: 12, xs: 12 }}>
            {<AssetStos stos={_asset?.stos} key={"1"} />}
          </Grid.Col>
        </Grid>
      </Container>
    </>
  );
}
