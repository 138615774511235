// import classes from "./Assets.module.css";
import { Text, Table } from "@mantine/core";
import { Holder } from "../../app/types/asset";
import { fetchUsers, useAppDispatch, useAppSelector } from "../../app/index";
import { formatNumber } from "../Helphers";
import { useContext, useEffect, useState } from "react";
import Context from "../../context/Context";

type Props = {
  holders?: { nodes: Holder[] };
};

export function Holders({ holders }: Props) {
  const context = useContext(Context);
  const dispatch = useAppDispatch();
  const [value, setValue] = useState("");
  const users = useAppSelector((state) => state.users);

  function getUserNameByDid(did: string): string {
    return users.response.find((user) => user.cdd === did)?.username ?? "N/A";
  }

  useEffect(() => {
    if (users.response.length === 0) {
      console.log("Detch Users");
      dispatch(fetchUsers(context.globalstate.service));
    }
  }, [value]);

  const ths = (
    <Table.Tr>
      <Table.Th>#</Table.Th>
      <Table.Th>User</Table.Th>
      <Table.Th>Balance</Table.Th>
    </Table.Tr>
  );

  const rows = holders?.nodes.map((holder, id) => (
    <Table.Tr key={id + 1}>
      <Table.Td>{id + 1}</Table.Td>
      <Table.Td>
        <Text>{getUserNameByDid(holder.did)}</Text>
      </Table.Td>
      <Table.Td>
        {formatNumber(parseFloat(holder.amount) / 1000000, 2)}
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <>
      <h2>Holders</h2>
      <Table highlightOnHover>
        <Table.Thead>{ths}</Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </>
  );
}
