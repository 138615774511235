import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";

const API_URL = process.env.REACT_APP_API_URL;

//  {
//       "id": 106,
//       "userid": 8,
//       "ticker": "PLANET01",
//       "amount": "34",
//       "amount_left": "34",
//       "price": "200",
//       "sold": "0",
//       "offer_status": "PENDING",
//       "created_at": "2023-09-08T13:06:24.510Z",
//       "address": "5CQCC41A8SyFrzoXCYkH2UPPXWNfmUeYCRCtXZw9XiLp9Xvc",
//       "cdd": "0x3e084c61efc7d57a9aee9372baf96e8f98f1fe716593966466464c3658d3007b"
// },

interface IExchangeOffering {
  id: number;
  userid: number;
  username: string;
  ticker: string;
  amount: string;
  amount_left: string;
  price: string;
  sold: string;
  offer_status: string;
  created_at: string;
  address: string;
  cdd: string;
}

interface IexchangeState {
  response: IExchangeOffering[];
  loading: boolean;
  error: object;
}

const initialState: IexchangeState = {
  response: [],
  loading: true,
  error: {},
};

export const fetchExchangeOfferings = createAsyncThunk(
  "admin/exchange",
  async (service: AxiosResponse) => {
    const uri = `${API_URL}/admin/exchange/offers`;
    try {
      const { data } = await service.request.get(uri);
      console.log("API Offeronsg Data: ", data);
      return data;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
);

export const exchangeOfferingSlice = createSlice({
  name: "exchange",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchExchangeOfferings.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchExchangeOfferings.fulfilled, (state, { payload }) => {
      console.log("API Exchange Payload", payload.data);
      state.response = payload.data;
      state.loading = false;
    });
    builder.addCase(fetchExchangeOfferings.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

// export const fetchEchangeTransactionDetails = createAsyncThunk(
//   "admin/exchangetransactions",
//   async (service: AxiosResponse) => {
//     const uri = `${API_URL}/admin/exchange/96`;
//     try {
//       const { data } = await service.request.get(uri);
//       console.log("API Offering TYransaction Data : ", data);
//       return data;
//     } catch (error) {
//       console.log(error);
//       return error;
//     }
//   }
// );

// export const exchangeTransactionSlice = createSlice({
//   name: "exchangetransactions",
//   initialState,
//   reducers: {},
//   extraReducers: (builder) => {
//     builder.addCase(fetchEchangeTransactionDetails.pending, (state) => {
//       state.loading = true;
//     });
//     builder.addCase(
//       fetchEchangeTransactionDetails.fulfilled,
//       (state, { payload }) => {
//         console.log("API Exchange Payload", payload.data);
//         state.response = payload.data;
//         state.loading = false;
//       }
//     );
//     builder.addCase(
//       fetchEchangeTransactionDetails.rejected,
//       (state, action) => {
//         state.loading = false;
//         state.error = action.error;
//       }
//     );
//   },
// });

export const exchangeOfferinSelector = (state: any) => state.offerings;
export default exchangeOfferingSlice.reducer;
