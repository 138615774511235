import { useContext, useEffect, useState } from "react";
import Context from "../../context/Context";
import { useAppDispatch, useAppSelector, gqlTokens } from "../../app/index";
import { Table } from "@mantine/core";
import { TokenListItem } from "./TokensListItem";

export default function TokensMain() {
  const context = useContext(Context);
  const dispatch = useAppDispatch();
  const [value, setValue] = useState("");

  const tokens = useAppSelector((state) => state.tokens);

  useEffect(() => {
    dispatch(gqlTokens());
  }, [value]);

  if (tokens.loading) return <h1>Loading Tokens ... </h1>;

  const ths = (
    <Table.Tr>
      <Table.Th>#</Table.Th>
      <Table.Th>Ticker</Table.Th>
      {/* <Table.Th>Name</Table.Th> */}
      <Table.Th>FundingRound</Table.Th>
      <Table.Th>Is Frozen</Table.Th>
      <Table.Th>Is Divisible</Table.Th>
      {/* <Table.Th>Is Uniqueness Required</Table.Th> */}
      <Table.Th>Total Transfers</Table.Th>
      <Table.Th>Total Supply</Table.Th>
      <Table.Th>Compliance Paused</Table.Th>
      <Table.Th>Created BlockId</Table.Th>
      <Table.Th>CreatedAt</Table.Th>
    </Table.Tr>
  );

  const rows = tokens.response?.assets.nodes.map((asset, id) => (
    <TokenListItem key={id + 1} asset={asset} id={id + 1} />
  ));
  return (
    <>
      <h1>Assets</h1>
      <Table highlightOnHover>
        <Table.Thead>{ths}</Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </>
  );
}
