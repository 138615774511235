import { useDisclosure } from "@mantine/hooks";
import {
  Notification,
  rem,
  Drawer,
  Button,
  TextInput,
  Group,
  PasswordInput,
  Box,
  LoadingOverlay,
} from "@mantine/core";
import { IconPlus, IconX, IconCheck } from "@tabler/icons-react";
import { useForm } from "@mantine/form";
import { useState, useContext } from "react";
import Context from "../../context/Context";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { fetchUsers } from "../../app/index";

const API_URL = process.env.REACT_APP_API_URL;

export function AddNewUserDrawer() {
  const context = useContext(Context);
  const dispatch = useAppDispatch();

  const [opened, { open, close }] = useDisclosure(false);
  const [processing, setProcessing] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [registrationFinished, setRegistrationFinished] = useState(false);

  const form = useForm({
    initialValues: {
      username: "",
      password: "",
      password1: "",
    },

    validate: {
      username: (value) =>
        /^\S+@\S+$/.test(value) ? null : "Invalid Username Email",
    },
  });

  // Get inferred form values type, will be `{ name: string; age: number }`
  type FormValues = typeof form.values;

  const handleSubmit = async (values: FormValues) => {
    setErrorVisible(false);
    const { username, password, password1 } = values;

    if (password1 !== password) {
      setErrorVisible(true);
      setErrorMessage("Passwords do not match");
      return;
    }

    setProcessing(true);

    const { service } = context.globalstate;
    const { status: httpStatusCode, data } = await service.request.put(
      `${API_URL}/user`,
      values
    );

    console.log("----------------------------------");

    // let httpStatusCode = 408;
    console.log("Register Response: -> httpStatusCode:  ", httpStatusCode);
    console.log("Register Response -> Data :  ", data);

    switch (httpStatusCode) {
      case 200: {
        console.log("Registration Successful");
        setProcessing(false);
        setRegistrationFinished(true);
        break;
      }
      case 409: {
        console.log("Requested Username Already Exists In Database");
        setErrorMessage("Username Already Exists In Database");
        setErrorVisible(true);
        setProcessing(false);
        break;
      }
      default: {
        console.log(
          "Something went wrong. Please try again later, or contact our administrator for further assistance."
        );
        setErrorMessage(
          "It seems that something went wrong. Please try again later, or contact our administrator for further assistance."
        );
        setErrorVisible(true);
        setProcessing(false);
        break;
      }
    }
  };

  const xIcon = <IconX style={{ width: rem(20), height: rem(20) }} />;
  const checkIcon = <IconCheck style={{ width: rem(20), height: rem(20) }} />;

  const closeMessage = () => {
    console.log("closeMessage");

    if (registrationFinished) {
      dispatch(fetchUsers(context.globalstate.service));
    }

    setErrorVisible(false);
    setErrorMessage("");
    setRegistrationFinished(false);
    form.reset();

    close();
  };

  return (
    <>
      <Drawer
        offset={8}
        radius="md"
        opened={opened}
        onClose={closeMessage}
        title="New User"
        position="right"
      >
        <>
          <Box pos="relative">
            <LoadingOverlay
              visible={processing}
              zIndex={1000}
              overlayProps={{ radius: "sm", blur: 2 }}
            />

            {errorVisible && ( // If errorVisible is true, then show the error message}
              <>
                <Notification
                  onClose={() => setErrorVisible(false)}
                  icon={xIcon}
                  color="red"
                  title="Error"
                >
                  {errorMessage}
                </Notification>
                <br />
              </>
            )}

            {registrationFinished && (
              <>
                <Notification
                  icon={checkIcon}
                  color="teal"
                  title="Success"
                  mt="md"
                  onClose={closeMessage}
                >
                  User Created Successfully
                </Notification>
                <br />
              </>
            )}

            {!registrationFinished && (
              <>
                <form
                  onSubmit={form.onSubmit((values) => handleSubmit(values))}
                >
                  <TextInput
                    withAsterisk
                    label="Email"
                    placeholder="Email address"
                    required
                    size="sm"
                    type="email"
                    {...form.getInputProps("username")}
                  />

                  <PasswordInput
                    label="Password"
                    placeholder="Password"
                    required
                    mt="sm"
                    size="sm"
                    {...form.getInputProps("password")}
                  />

                  <PasswordInput
                    label="Repeate password"
                    placeholder="Repeate password"
                    required
                    mt="sm"
                    size="sm"
                    {...form.getInputProps("password1")}
                  />

                  <Group justify="flex-end" mt="md">
                    <Button type="submit" mt="md">
                      {" "}
                      Add New User{" "}
                    </Button>
                  </Group>
                </form>
              </>
            )}
          </Box>
        </>
      </Drawer>

      <Button
        onClick={open}
        size="md"
        leftSection={<IconPlus size={14} />}
        color="gray"
      >
        New User
      </Button>
    </>
  );
}
