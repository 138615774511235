import classes from "./Assets.module.css";
import { Table } from "@mantine/core";
import { Investment } from "../../app/types/index";
import { useAppSelector } from "../../app/index";
import { formatNumber } from "../Helphers";

interface AssetInvestmentsProps {
  investments:
    | {
        totalCount: number;
        nodes: Investment[];
      }
    | undefined;
}

export function Investments({ investments }: AssetInvestmentsProps) {
  const users = useAppSelector((state) => state.users);

  function getUserNameByDid(did: string): string {
    return users.response.find((user) => user.cdd === did)?.username ?? "N/A";
  }

  const ths = (
    <Table.Tr>
      <Table.Th>#</Table.Th>
      <Table.Th>User</Table.Th>
      <Table.Th>Offering Token</Table.Th>
      <Table.Th>Raise Token</Table.Th>
      <Table.Th>Offering Token Amount</Table.Th>
      <Table.Th>Raise Token Amount</Table.Th>
    </Table.Tr>
  );

  // {
  //     "createdBlockId": "9605636",
  //     "investor": "0x3e084c61efc7d57a9aee9372baf96e8f98f1fe716593966466464c3658d3007b",
  //     "stoId": 0,
  //     "offeringToken": "ANDI1",
  //     "raiseToken": "USDT",
  //     "offeringTokenAmount": "12000000",
  //     "raiseTokenAmount": "12000000",
  //     "datetime": "2023-08-08T18:43:30"
  // },

  const rows = investments?.nodes.map((investment: Investment, id: number) => (
    <Table.Tr key={id + 1}>
      <Table.Td>{id + 1}</Table.Td>
      <Table.Td>{getUserNameByDid(investment.investor)}</Table.Td>
      <Table.Td>{investment.offeringToken}</Table.Td>
      <Table.Td>{investment.raiseToken}</Table.Td>
      <Table.Td>
        {formatNumber(parseFloat(investment.offeringTokenAmount) / 1000000)}
      </Table.Td>
      <Table.Td>
        {formatNumber(parseFloat(investment.raiseTokenAmount) / 1000000)}
      </Table.Td>
    </Table.Tr>
  ));
  return (
    <>
      <h2> Investments: {investments?.totalCount}</h2>{" "}
      <Table highlightOnHover>
        <Table.Thead>{ths}</Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </>
  );
}
