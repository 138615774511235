import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { apolloClient } from "./graphQlClient";
import { gql } from "@apollo/client";
import { Tokens } from "./types";

const TOKEN_ISSUER_DID = process.env.REACT_APP_TOKEN_ISSUER_DID || "";

interface ITokensState {
  response: Tokens | null;
  loading: boolean;
  error: object;
}
const initialState: ITokensState = {
  response: null,
  loading: true,
  error: {},
};

// {
//     tokenIssuerDid = TOKEN_ISSUER_DID,
//   }: {
//     tokenIssuerDid?: string;
//   }

export const gqlTokens = createAsyncThunk("graphql/tokens", async () => {
  const query = gql`
      {
        assets(
          filter: {
            ownerId: {
              equalTo: "${TOKEN_ISSUER_DID}"
            }
          }
          orderBy: CREATED_BLOCK_ID_DESC
        ) {
          nodes {
            ticker
            name
            type
            fundingRound
            isFrozen
            isDivisible
            isUniquenessRequired
            identifiers
            totalTransfers
            totalSupply
            isCompliancePaused
            eventIdx
            createdBlockId
            createdAt
            updatedAt
            transferManagers {
              nodes {
                type
                value
                exemptedEntities
              }
            }
            compliance {
              nodes {
                id: complianceId
                data
              }
            }
            holders {
              nodes {
                did: identityId
                amount
              }
            }
            documents {
              nodes {
                id: documentId
                name
                link
                contentHash
                type
                filedAt
              }
            }
          }
        }
      }
    `;

  console.log(query);

  const q = { query: query };

  try {
    const { data } = await apolloClient.query(q);
    console.log("TOKENS ==> GraphQL Data ==> ", data.assets);
    return data;
  } catch (error) {
    console.log("TOKENS ==> GraphQL Error  ==> ", error);
    return {};
  }
});

export const tokensSlice = createSlice({
  name: "tokens",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(gqlTokens.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(gqlTokens.fulfilled, (state, { payload }) => {
      state.response = payload;
      state.loading = false;
    });
    builder.addCase(gqlTokens.rejected, (state, action) => {
      console.log("fetchSubgraphData.rejected => ", action);
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const tokensSliceSelector = (state: any) => state.tokens;
// export const { someActionFromThisFile } = tokensSlice.actions;
export default tokensSlice.reducer;
