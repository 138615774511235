import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextInput,
  PasswordInput,
  Title,
  Container,
  Group,
  Button,
  Image,
  Center,
  LoadingOverlay,
  Card,
  Grid,
  AppShell,
  Alert,
} from "@mantine/core";

import { useForm } from "@mantine/form";
import MaeiBigLogo from "../assets/svg/maei.svg";
import Context from "../context/Context";
import { IconAlertCircle } from "@tabler/icons-react";

// const REACT_APP_STAGE = process.env.REACT_APP_STAGE;
// const REACT_APP_VERSION = process.env.REACT_APP_VERSION;

// Fil inputs for quick login
const REACT_APP_USER = process.env.REACT_APP_USER || "";
const REACT_APP_PASSWORD = process.env.REACT_APP_PASSWORD || "";

export default function Login() {
  const context = useContext(Context);
  let navigate = useNavigate();

  const [processing, setProcessing] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");

  // For quick Login: TODO: Remove in production
  let [username, setUsername] = useState(REACT_APP_USER);
  let [password, setPassword] = useState(REACT_APP_PASSWORD);

  const form = useForm({
    initialValues: {
      username: username,
      password: password,
    },

    validate: {
      username: (value) =>
        /^\S+@\S+$/.test(value) ? null : "Invalid Username Email",
    },
  });

  // Get inferred form values type, will be `{ name: string; age: number }`
  type FormValues = typeof form.values;

  const handleSubmit = async (values: FormValues) => {
    console.log(values);
    const { username, password } = values;

    setProcessing(true);

    const { login } = context.handlers;
    const { status: httpStatusCode, data } = await login(username, password);

    switch (httpStatusCode) {
      case 200: {
        console.log("Login Success !!! Redirect To Home");
        navigate("/");
        break;
      }
      case 404: {
        console.log("User Not Found");
        setLoginErrorMessage("Wrong username or password");
        setLoginError(true);
        setProcessing(false);
        break;
      }
      default: {
        console.log("Something Wrong Happned , Please ask Administrator");
        setLoginErrorMessage(
          "Something Wrong Happned , Please ask Administrator"
        );
        setLoginError(true);
        setProcessing(false);
        break;
      }
    }
  };

  return (
    <>
      <AppShell>
        <Container size={340} my={40}>
          <LoadingOverlay
            visible={processing}
            zIndex={1000}
            overlayProps={{ radius: "sm", blur: 2 }}
          />

          <Grid grow gutter="xl">
            <Grid.Col span={12}>
              <Center>
                <Image width={200} src={MaeiBigLogo} alt="maei.cash logo" />
              </Center>
            </Grid.Col>
            <Grid.Col span={12}>
              <Center>
                <Title>Maei Admin</Title>
              </Center>
            </Grid.Col>
            <Grid.Col span={12}>
              <Card shadow="sm" padding="lg" radius="md" withBorder>
                {loginError && (
                  <>
                    <Alert
                      icon={<IconAlertCircle />}
                      title="Login Error"
                      color="red"
                      withCloseButton
                      onClose={() => setLoginError(false)}
                    >
                      {loginErrorMessage}
                    </Alert>
                    <br />
                  </>
                )}
                <form
                  onSubmit={form.onSubmit((values) => handleSubmit(values))}
                >
                  <TextInput
                    withAsterisk
                    label="Email"
                    placeholder="name@company.com"
                    required
                    {...form.getInputProps("username")}
                  />
                  <PasswordInput
                    label="Password"
                    placeholder="Your password"
                    required
                    mt="md"
                    {...form.getInputProps("password")}
                  />

                  <Group grow mt="xl">
                    <Button fullWidth color={"dark"} type="submit">
                      Login
                    </Button>
                  </Group>
                </form>
              </Card>
            </Grid.Col>
          </Grid>
        </Container>
      </AppShell>
    </>
  );
}
