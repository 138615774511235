import axios, { AxiosInstance, AxiosResponse, AxiosError } from "axios";
const API_URL = process.env.REACT_APP_API_URL;

class Service {
  token: string;
  request: AxiosInstance;
  constructor(token: string) {
    this.token = token;
    this.request = axios.create({
      baseURL: API_URL,
      headers: { Authorization: "Bearer " + this.token },
    });
    this.request.interceptors.response.use(
      this.handleSuccess,
      this.handleError
    );
  }

  handleSuccess(response: AxiosResponse): AxiosResponse {
    // console.log("The response in Axious : ");
    // console.log(response);
    // console.log("----");
    return response;
  }

  handleError = (error: AxiosError) => {
    console.log("--- Axous Class Error ---");
    console.log(error.toJSON());
    console.log("Error Code : ", error.response);
    console.log("--------------------------");

    //this.redirectTo(document, '/login?'+error.response.status);
    // switch (error.response.status) {
    // 	case 401:
    // 		this.redirectTo(document, '/login?401')
    // 		break;
    // 	case 404:
    // 		this.redirectTo(document, '/404')
    // 		break;
    // 	default:
    // 		this.redirectTo(document, '/')
    // 		break;
    // }
    return error.response;
    //return Promise.reject(error)
  };

  //   redirectTo = (document, path) => {
  //     document.location = path;
  //   };
}

export default Service;
