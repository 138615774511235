import React, { useState, useEffect, useContext } from "react";
import Context from "../../context/Context";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { fetchUsers } from "../../app/index";
import {
  ActionIcon,
  Anchor,
  Badge,
  CopyButton,
  Group,
  Table,
  Text,
  Tooltip,
  rem,
} from "@mantine/core";
import { CreateDid } from "./CreateDID";
import { formatNumber, truncateText } from "../Helphers";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  IconCheck,
  IconChevronRight,
  IconCopy,
  IconExternalLink,
} from "@tabler/icons-react";
import { AddNewUserDrawer } from "./AddUserDrawer";
import { usePreviousPath } from "../Hooks/PreviousLocationHook";

export const UsersList: React.FC = () => {
  const context = React.useContext(Context);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const users = useAppSelector((state) => state.users);
  const [value, setValue] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    console.log("----****----");
    console.log("location: ", location.state);
    if (!location.state || location.state.needrefresh) {
      dispatch(fetchUsers(context.globalstate.service));
    }
  }, [value]);

  if (users.loading) {
    return (
      <div className="container-fluid">
        <div> Loading .. </div>
      </div>
    );
  }

  const ths = (
    <Table.Tr>
      <Table.Th>#</Table.Th>
      <Table.Th>UserName</Table.Th>
      <Table.Th>Role</Table.Th>
      <Table.Th>Polyx</Table.Th>
      <Table.Th>Maei</Table.Th>
      <Table.Th>Identity</Table.Th>
      <Table.Th>Address</Table.Th>
      <Table.Th></Table.Th>
    </Table.Tr>
  );

  const rows = users.response.map((user, key) => (
    <Table.Tr key={"id" + user.id}>
      <Table.Td>{key + 1}</Table.Td>
      <Table.Td>
        <Link to={`/user/${user.id}`}>{user.username}</Link>
      </Table.Td>
      <Table.Td>
        {user.role === "admin" ? (
          <Badge variant="outline" color="green">
            {user.role}
          </Badge>
        ) : (
          <Badge variant="outline" color="blue">
            {user.role}
          </Badge>
        )}
      </Table.Td>
      <Table.Td>
        <Text fw={500}>{formatNumber(user.polyxBalance)}</Text>
      </Table.Td>
      <Table.Td>
        {user.maeiBalance > 0 ? (
          <Text fw={500}>{formatNumber(user.maeiBalance, 2)}</Text>
        ) : (
          <Text fw={500}>0.00</Text>
        )}
      </Table.Td>
      <Table.Td>
        <CreateDid cdd={user.cdd} address={user.address} id={user.id} />{" "}
      </Table.Td>
      <Table.Td>
        <Group gap="lg" grow>
          <Text size="md"> {truncateText(user.address, 6)} </Text>

          <CopyButton value={user.address} timeout={2000}>
            {({ copied, copy }) => (
              <Tooltip
                label={copied ? "Copied" : `Copy: ${user.address}`}
                withArrow
                position="right"
              >
                <ActionIcon
                  color={copied ? "teal" : "gray"}
                  variant="subtle"
                  onClick={copy}
                >
                  {copied ? (
                    <IconCheck style={{ width: rem(16) }} />
                  ) : (
                    <IconCopy style={{ width: rem(16) }} />
                  )}
                </ActionIcon>
              </Tooltip>
            )}
          </CopyButton>

          <Tooltip
            label="Open in Subsacan.io exproeller"
            withArrow
            position="right"
          >
            <Anchor
              href={` ${process.env.REACT_APP_SUBSCAN_URL}/account/${user.address} `}
              target="_blank"
            >
              <IconExternalLink size={20} color="gray" />
            </Anchor>
          </Tooltip>
        </Group>
      </Table.Td>
      <Table.Td>
        <Group gap="lg" grow>
          <ActionIcon
            size="xl"
            variant="white"
            radius="sm"
            aria-label="Settings"
            onClick={() => navigate(`/user/` + user.id)}
          >
            <IconChevronRight
              style={{ width: "70%", height: "70%" }}
              stroke={1.5}
            />
          </ActionIcon>
        </Group>
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <>
      <Group justify="flex-end" gap="xl" grow>
        <h1>Users </h1>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>
          <AddNewUserDrawer />
        </div>
      </Group>
      <Table highlightOnHover>
        <Table.Thead>{ths}</Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </>
  );
};
