import React, { useContext, useEffect } from "react";
import { AppShell, Burger, Group } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { NavbarMinimal } from "./Components/NavbarMinimal";
import { LogoMaeyBoxLagre } from "../assets";

interface Props {
  children?: React.ReactNode;
}

const Layout: React.FC<Props> = ({ children }: Props) => {
  const [opened, { toggle }] = useDisclosure();

  return (
    <React.Fragment>
      <AppShell
        header={{ height: 100 }}
        navbar={{
          width: 81,
          breakpoint: "sm",
          collapsed: { mobile: !opened },
        }}
        padding="md"
      >
        {/* <AppShell.Header>
          <Group justify="space-between" h="100%">
            <Link to="/">
              <img src={LogoMaeyBoxLagre} alt="Maei Logo" height="50" />
            </Link>
          </Group>
        </AppShell.Header> */}

        <AppShell.Header>
          <Group h="100%" px="md">
            <Burger
              opened={opened}
              onClick={toggle}
              hiddenFrom="sm"
              size="sm"
            />
            <Group justify="space-between" style={{ flex: 1 }}>
              <img src={LogoMaeyBoxLagre} alt="Maei Logo" height="30" />
              <Group ml="xl" gap={0} visibleFrom="sm">
                {/* <UnstyledButton className={classes.control}>
                  Home
                </UnstyledButton> */}
                {/* <WebScoketComponent /> */}
              </Group>
            </Group>
          </Group>
        </AppShell.Header>

        <NavbarMinimal />

        <AppShell.Main>{children}</AppShell.Main>
      </AppShell>
    </React.Fragment>
  );
};

export default Layout;
