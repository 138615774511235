// import { useMediaQuery } from "@mantine/hooks";
// import { useContext, useEffect, useState } from "react";
// import Context from "./context/Context";
import RoutingComponent from "./pages/RoutingComponent";

export function App() {
  // const matches = useMediaQuery("(min-width: 56.25em)");
  // const context = useContext(Context);
  // useEffect(() => {}, []);

  return (
    <>
      <RoutingComponent />
    </>
  );
}

export default App;
