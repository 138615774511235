import React, { useState, useEffect, useContext } from "react";
import Context from "../../context/Context";
import { IUser } from "../../app/types";
import {
  ActionIcon,
  Button,
  CopyButton,
  Group,
  Tooltip,
  rem,
  Text,
} from "@mantine/core";
import { IconCheck, IconCopy, IconDatabase } from "@tabler/icons-react";
import { truncateText } from "../Helphers";

interface IUSER {
  id: number;
  cdd?: string;
  address: string;
}

const API_URL = process.env.REACT_APP_API_URL;

export const CreateDid: React.FC<IUSER> = ({ cdd = "", address = "", id }) => {
  const context = useContext(Context);
  const [value, setValue] = useState("");
  const [did, setDid] = useState<string | null>();
  const [account, setAccount] = useState<boolean | string>();
  const [proccessing, setPoccessing] = useState(false);

  async function createDid() {
    setPoccessing(true);

    const request = {
      userId: id,
      address: address,
    };

    const { service } = context.globalstate;
    const { data } = await service.request.post(
      `${API_URL}/admin/registerdid`,
      request
    );

    //-- TODO Improve this part --//
    console.log(data);
    const { successs, did } = data;
    console.log("Did response :", did);
    setDid(did);
    // toast.success(`Success: ${did} `);
    setPoccessing(false);
  }

  useEffect(() => {
    setDid(cdd);
    setAccount(address);
  }, [value]);

  if (!did) {
    return (
      <>
        {/* <Toaster
          toastOptions={{
            position: "top-right",
            duration: 5000,
            className: "",
            style: {
              zIndex: 9999999,
              padding: "10px",
            },
          }}
        /> */}

        <Button
          loading={proccessing}
          variant="outline"
          loaderProps={{ type: "dots" }}
          onClick={createDid}
          leftSection={<IconDatabase size="1rem" />}
        >
          Create DID
        </Button>
      </>
    );
  }
  return (
    <>
      <Group>
        <Text size="md">{truncateText(did, 9)}</Text>
        <div>
          <CopyButton value={did} timeout={2000}>
            {({ copied, copy }) => (
              <Tooltip
                label={copied ? "Copied" : "Copy DID"}
                withArrow
                position="right"
              >
                <ActionIcon
                  color={copied ? "teal" : "gray"}
                  variant="subtle"
                  onClick={copy}
                >
                  {copied ? (
                    <IconCheck style={{ width: rem(16) }} />
                  ) : (
                    <IconCopy style={{ width: rem(16) }} />
                  )}
                </ActionIcon>
              </Tooltip>
            )}
          </CopyButton>
        </div>
        <div></div>
      </Group>
    </>
  );
};
