import React, { useState, useEffect, useContext } from "react";
import Context from "../../context/Context";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Drawer, Table } from "@mantine/core";
import { fetchExchangeOfferings } from "../../app/index";
import { Link } from "react-router-dom";
import { formatDateLocale } from "../Helphers";
const API_URL = process.env.REACT_APP_API_URL;

interface ExchangeTransaction {
  id: number;
  offerid: number;
  userid: number;
  username: string;
  amount: string;
  tx_id: string;
  created_at: string;
  updated_at: string;
  meta: {};
}

function Exchange() {
  const [value, setValue] = useState("");
  const [opened, setOpened] = useState(false);

  const [exchangeTransactions, setExchangeTransactions] = useState<
    ExchangeTransaction[]
  >([]);

  const context = useContext(Context);
  const dispatch = useAppDispatch();
  const exchange = useAppSelector((state) => state.exchange);

  function closeDrawer() {
    console.log("closeDrawer");
    setOpened(false);
  }

  async function openExchangeTransactions(offerId: number) {
    console.log("openExchangeTransactioons", offerId);
    const uri = `${API_URL}/admin/exchange/${offerId}`;

    console.log("openExchangeTransactions Uri ", uri);

    try {
      const { data } = await context.globalstate.service.request.get(uri);
      console.log("Exchange Transactions ... : ", data);
      setExchangeTransactions(data.data);
    } catch (error) {
      console.log("API Error: ", error);
      // @TODO: show error code somehow
    }

    setOpened(true);
  }

  useEffect(() => {
    dispatch(fetchExchangeOfferings(context.globalstate.service));
  }, [value]);

  if (exchange.loading) return <p>Loading exchange data ...</p>;

  //  {
  //       "id": 106,
  //       "userid": 8,
  //       "username": "test3@gmail.com",
  //       "ticker": "PLANET01",
  //       "amount": "34",
  //       "amount_left": "34",
  //       "price": "200",
  //       "sold": "0",
  //       "offer_status": "PENDING",
  //       "created_at": "2023-09-08T13:06:24.510Z",
  //       "address": "5CQCC41A8SyFrzoXCYkH2UPPXWNfmUeYCRCtXZw9XiLp9Xvc",
  //       "cdd": "0x3e084c61efc7d57a9aee9372baf96e8f98f1fe716593966466464c3658d3007b"
  // },

  const rows = exchange.response.map((offer, key) => (
    <Table.Tr
      key={"id" + offer.id}
      onClick={() => openExchangeTransactions(offer.id)}
    >
      <Table.Td>{offer.id}</Table.Td>
      <Table.Td>
        <Link to={`/user/${offer.userid}`}>{offer.username}</Link>
      </Table.Td>
      <Table.Td>
        <Link to={`/token/${offer.ticker}`}>{offer.ticker}</Link>
      </Table.Td>
      <Table.Td>{offer.amount}</Table.Td>
      <Table.Td>{offer.amount_left}</Table.Td>
      <Table.Td>{offer.price}</Table.Td>
      <Table.Td>{offer.offer_status}</Table.Td>
      <Table.Td>
        {formatDateLocale(new Date(offer.created_at), "dd/MMM/yyyy")}
      </Table.Td>
    </Table.Tr>
  ));

  const detailsRows = exchangeTransactions.map((tx, key) => (
    <Table.Tr key={"tx" + tx.id}>
      <Table.Td>{tx.offerid}</Table.Td>
      <Table.Td>{tx.username}</Table.Td>
      <Table.Td>{tx.amount}</Table.Td>
      <Table.Td>{formatDateLocale(new Date(tx.created_at))}</Table.Td>
    </Table.Tr>
  ));

  return (
    <>
      <h1>Token Exchange</h1>
      <Drawer
        opened={opened}
        onClose={closeDrawer}
        title="Exchange Offer {}"
        position="right"
      >
        <Table verticalSpacing="xs" highlightOnHover>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>#</Table.Th>
              <Table.Th>#</Table.Th>
              <Table.Th>Amount</Table.Th>
              <Table.Th>Date</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{detailsRows}</Table.Tbody>
        </Table>
      </Drawer>

      <Table.ScrollContainer minWidth={800}>
        <Table verticalSpacing="xs" highlightOnHover>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Offer ID</Table.Th>
              <Table.Th>UserName</Table.Th>
              <Table.Th>Ticker</Table.Th>
              <Table.Th>Amount {"Amount Created"}</Table.Th>
              <Table.Th>Amount left {"initial Amount  - Sold "} </Table.Th>
              <Table.Th>Price {"Price Per token"} </Table.Th>
              <Table.Th>Offer Status</Table.Th>
              <Table.Th>Date</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </Table.ScrollContainer>
    </>
  );
}

export default Exchange;
