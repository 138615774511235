import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  Distribution,
  ProcessTransactionResponse,
} from "./types/distributions";
import { RootState } from "./store"; //
import axios from "axios";
import { socket } from "../WebsocketClient";

const API_URL = process.env.REACT_APP_API_URL;

const initialState: Distribution = {
  loading: false,
  token: "",
  usersToDistribute: [],
  activeStep: 0,
  error: {},
};

export const postDistributionData = createAsyncThunk(
  "distribution/postData",
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;

    // @TODO  final check if data is not empty !!!
    const distributionData = state.assetdistribution;
    const uri = `${API_URL}/workflow/start`;

    try {
      const response = await axios.post(uri, {
        data: distributionData,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const distributionSlice = createSlice({
  name: "distribution",
  initialState,
  reducers: {
    // Existing reducers...
    setActiveStep: (state, action) => {
      state.activeStep = action.payload;
    },
    nextStep: (state) => {
      if (state.activeStep < 3) {
        state.activeStep += 1;
      }
    },
    prevStep: (state) => {
      if (state.activeStep > 0) {
        state.activeStep -= 1;
      }
    },
    addUser: (state, action) => {
      console.log("Attempting to add user to distribution ... ");
      console.log(action.payload);
      const existingUser = state.usersToDistribute.find(
        (user) => user.userId === action.payload.userId
      );
      if (!existingUser) {
        state.usersToDistribute.push(action.payload);
        console.log("User added.");
      } else {
        console.log("User already exists. No action taken.");
      }
    },
    removeUser: (state, action) => {
      console.log("Removing user from distribution ... ");
      console.log(action.payload);
      state.usersToDistribute = state.usersToDistribute.filter(
        (user) => user.userId !== action.payload.userId
      );
    },
    // Add this to the reducers in your distributionSlice
    updateTokenAmount: (state, action) => {
      const { userId, newAmount } = action.payload;
      const user = state.usersToDistribute.find(
        (user) => user.userId === userId
      );
      if (user) {
        user.tokenAmount = newAmount;
      }
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    resetDistribution: () => initialState,
    updateUserDistributionStatus: (
      state,
      action: PayloadAction<ProcessTransactionResponse>
    ) => {
      console.log("Reducer !!! Updating user distribution status ... ");
      const { userId, success, errorCode } = action.payload;
      const user = state.usersToDistribute.find(
        (user) => user.userId === userId
      );
      if (user) {
        user.distributionStatus = { success, userId, errorCode };
        user.processing = false; // Finish processing
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postDistributionData.pending, (state) => {
        console.log("Active step : ", state.activeStep);
        state.loading = true;
        state.usersToDistribute.forEach((user) => {
          user.processing = true;
        });
      })
      .addCase(postDistributionData.fulfilled, (state, action) => {
        state.loading = false;
        state.activeStep = state.activeStep + 1;
      })
      .addCase(postDistributionData.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const {
  setActiveStep,
  nextStep,
  prevStep,
  addUser,
  removeUser,
  updateTokenAmount,
  setToken,
  resetDistribution,
  updateUserDistributionStatus,
} = distributionSlice.actions;

export const selectDistributionData = (state: any) => state.distribution;
export default distributionSlice.reducer;
