import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";
import "@mantine/core/styles.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { MantineProvider, createTheme } from "@mantine/core";
import { Notifications } from "@mantine/notifications";

import { BrowserRouter } from "react-router-dom";
import AppProvider from "./context/Provider";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { ModalsProvider } from "@mantine/modals";

import setupWebSocketListeners from "./app/websocketListener";

import "@mantine/notifications/styles.css";

const theme = createTheme({
  // @TODO finish theme customization
  // fontFamily: "Open Sans, sans-serif",
  // primaryColor: "cyan",
});

setupWebSocketListeners();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppProvider>
        <Provider store={store}>
          <MantineProvider theme={theme}>
            <ModalsProvider>
              <Notifications zIndex={2077} position={"top-right"} />
              <App />
            </ModalsProvider>
          </MantineProvider>
        </Provider>
      </AppProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
