import { configureStore } from "@reduxjs/toolkit";
import usersReducer from "./usersSlice";
import offeringsReducer from "./offeringsSlice";
import fundsreducer from "./fundsSlice";
import appSettingsSlice from "./appSettingsSlice";
import appReducer from "./socketSlice";
import tokenReducer from "./graphQlTokensSlice";
import websocketReducer from "./websocketSlice";
import assetReducer from "./graphQlAssetsSlice";
import userDetailsReducer from "./graphQlUserSlice";
import exchangeReducer from "./exchangeSlice";
import distrubuteReducer from "./tokenDistributionSlice";

export const store = configureStore({
  reducer: {
    users: usersReducer,
    offerings: offeringsReducer,
    funds: fundsreducer,
    appsettings: appSettingsSlice,
    socket: appReducer,
    tokens: tokenReducer,
    websocket: websocketReducer,
    asset: assetReducer,
    userdetails: userDetailsReducer,
    exchange: exchangeReducer,
    assetdistribution: distrubuteReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
