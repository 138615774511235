import React, { useContext } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Context from "../context/Context";
import Layout from "./Layout";
import Login from "./Login";
import { Users } from "./Users/index";
import { Funds } from "./Funds";
import { TokensMain } from "./Assets";
import PlaygroundMain from "./Playground/Index";
import AssetDetails from "./Assets/AssetDetails";
import UserDetails from "./Users/UserDetails";
import Exchange from "./Exchange/Index";
import TokenDistribution from "./Distributions";

const useAuth = () => {
  const context = useContext(Context);
  const auth = context.globalstate.isLoggedIn;
  return auth;
};

const ProtectedRoute = ({ children }: { children: any }) => {
  const location = useLocation();
  const isAuth = useAuth();
  return isAuth ? (
    children
  ) : (
    <Navigate to="/login" replace state={{ from: location }} />
  );
};

function RoutingComponent() {
  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Layout>{<Users />}</Layout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/user/:userid"
          element={
            <ProtectedRoute>
              <Layout>{<UserDetails />}</Layout>{" "}
            </ProtectedRoute>
          }
        />

        <Route
          path="/Funds"
          element={
            <ProtectedRoute>
              <Layout>{<Funds />} </Layout>{" "}
            </ProtectedRoute>
          }
        />

        <Route
          path="/Tokens"
          element={
            <ProtectedRoute>
              <Layout>{<TokensMain />} </Layout>{" "}
            </ProtectedRoute>
          }
        />

        <Route
          path="/token/:ticker"
          element={
            <ProtectedRoute>
              <Layout>{<AssetDetails />} </Layout>{" "}
            </ProtectedRoute>
          }
        />

        <Route
          path="/exchange/"
          element={
            <ProtectedRoute>
              <Layout>{<Exchange />} </Layout>{" "}
            </ProtectedRoute>
          }
        />

        <Route
          path="/distribution/"
          element={
            <ProtectedRoute>
              <Layout>{<TokenDistribution />} </Layout>{" "}
            </ProtectedRoute>
          }
        />

        <Route
          path="/test"
          element={
            <ProtectedRoute>
              {" "}
              <Layout> {<PlaygroundMain />} </Layout>
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  );
}

export default RoutingComponent;
