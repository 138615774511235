import { createSlice } from "@reduxjs/toolkit";

// Navbar is Hidden property: https://mantine.dev/core/app-shell/?t=props
interface IAppSetting {
  navigationOpened: boolean;
  navHidden: boolean; // this is Navbar Propery Very specific to Mantine
  appVersion?: string;
}

const initialState: IAppSetting = {
  navigationOpened: false,
  navHidden: true, // this hidden means that the navbar is not visible
  appVersion: "1.0.1",
};

export const appSettingsSlice = createSlice({
  name: "appsettings",
  initialState,
  reducers: {
    openNavbar: (state, action) => {
      console.log("Store: openNavbar ===> payload ===> ", action);
      state.navigationOpened = action.payload;
    },
    closeNavbar: (state: IAppSetting) => {
      state.navigationOpened = false;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {},
});

export const { openNavbar, closeNavbar } = appSettingsSlice.actions;
export const appSettingsStateSelector = (state: IAppSetting) => state;
export default appSettingsSlice.reducer;
