import React, { Component } from "react";
import Context from "./Context";
import axios from "axios";
import Service from "./WrappedAxious";

const API_URL = process.env.REACT_APP_API_URL;

class Provider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      service: new Service(),
      jwtToken: null,
      isLoggedIn: false,
    };
  }

  // getTransactions = async () => {
  //   const response = await this.state.service.request.get(
  //     `${API_URL}/v2/mytransactions`
  //   );
  //   console.log("Transactions response :  ", response.data);
  //   this.setState({ transactions: response.data });
  // };

  // getStoList = async () => {
  //   const response = await this.state.service.request.get(`${API_URL}/v2/sto`);
  //   this.setState({ stoList: response.data });
  // };

  setJwtToken = async (token) => {
    this.setState({ jwtToken: token });
    this.setState({ service: new Service(token) });
    this.setState({ isLoggedIn: true });
  };

  logout = () => {
    this.setState({ jwtToken: null });
    this.setState({ isLoggedIn: false });
  };

  login = async (username, password) => {
    const loginData = {
      username: username,
      password: password,
    };

    const response = await axios
      .post(`${API_URL}/user/`, loginData)
      .catch(function (error) {
        console.log(error);
      });

    if (response) {
      console.log("Login response :  ", response);
      await this.setJwtToken(response.data.token);
    }
    return response;
  };

  async componentDidMount() {}

  render() {
    const handlers = {
      login: this.login,
      logout: this.logout,
    };
    return (
      <Context.Provider value={{ globalstate: this.state, handlers: handlers }}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

export default Provider;
