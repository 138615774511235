import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { apolloClient } from "./graphQlClient";
import { gql } from "@apollo/client";
import { IUserDetails } from "./types";

interface IUserDetailsState {
  response: IUserDetails | null;
  loading: boolean;
  error: object;
}
const initialState: IUserDetailsState = {
  response: null,
  loading: true,
  error: {},
};

// const USER_DID = "";
export const fetchUserDetailsFromGraph = createAsyncThunk(
  "graphql/userdetails",
  async ({ userDid }: { userDid: string }) => {
    // console.log("USER DETAILS ==> GraphQL Query ==> ", userDid);

    const query = gql`
    query {
      assetHolders(
        filter: {
          identityId: {
            equalTo: "${userDid}"
          }
        }
      ) {
        nodes {
          nodeId
          id
          identityId
          assetId
          amount
          createdBlockId
          updatedBlockId
          createdAt
          updatedAt
        }
      }

      investments(
        filter: {
          investorId: {
            equalTo: "${userDid}"
          }
        }
      ) {
        totalCount
        nodes {
          nodeId
          id
          investorId
          stoId
          offeringToken
          raiseToken
          offeringTokenAmount
          raiseTokenAmount
          datetime
          createdBlockId
          updatedBlockId
          createdAt
          updatedAt
        }
      }
    }
  `;

    const q = { query: query };

    try {
      const { data } = await apolloClient.query(q);
      // console.log("USER DETAILS ==> GraphQL Data ==> ", data);
      return data;
    } catch (error) {
      console.log("USER DETAILS ==> GraphQL Error  ==> ", error);
      return {};
    }
  }
);

export const userDetailsSlice = createSlice({
  name: "userdetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserDetailsFromGraph.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchUserDetailsFromGraph.fulfilled,
      (state, { payload }) => {
        state.response = payload;
        state.loading = false;
      }
    );
    builder.addCase(fetchUserDetailsFromGraph.rejected, (state, action) => {
      console.log("fetchSubgraphData.rejected => ", action);
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const userDetailsSliceSelector = (state: any) => state.userdetails;
export default userDetailsSlice.reducer;
