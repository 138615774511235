import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { apolloClient } from "./graphQlClient";
import { gql } from "@apollo/client";
import { AssetByTiker } from "./types/assets";
import { IUser } from "./types/user";
import { Asset } from "./types/asset";

interface IAssetState {
  response: AssetByTiker | null;
  loading: boolean;
  error: object;
}

const initialState: IAssetState = {
  response: null,
  loading: true,
  error: {},
};

// Actually We have already Assets Class and can be extende that
// I ve decided to split Assets and Assets by Toiker ... lets see in the future

/// One Asset One !!! ///

export const gqlAssetByTiker = createAsyncThunk(
  "graphql/asset",
  async ({ tiker }: { tiker: string }, thunkAPI: any) => {
    const query = gql`
    
      # graphQL passing asset tiker as a parameter in 4 places:
      # param: ${tiker} passed to:  assets | stos | investments | fundings

      {
        assets(filter: { ticker: { equalTo: "${tiker}" } }) {
          nodes {
            nodeId
            id
            ticker
            name
            type
            fundingRound
            isDivisible
            isFrozen
            isUniquenessRequired
            identifiers
            ownerDid: ownerId
            totalSupply
            totalTransfers
            isCompliancePaused
            transferManagers {
              nodes {
                type
                value
                exemptedEntities
              }
            }
            compliance {
              nodes {
                id: complianceId
                data
              }
            }
            holders {
              nodes {
                did: identityId
                amount
              }
            }
            documents {
              nodes {
                id: documentId
                name
                link
                contentHash
                type
                filedAt
              }
            }
          }
        }
        stos(
          filter: { offeringAssetId: { equalTo: "${tiker}" } }
          orderBy: CREATED_BLOCK_ID_DESC
        ) {
          nodes {
            id
            stoId
            offeringAsset {
              ticker
            }
            name
            raisingAssetId
            status
            start
            end
            tiers
            minimumInvestment
            createdAt
            createdBlockId
          }
        }
        investments(filter: { offeringToken: { equalTo: "${tiker}" } }) {
          totalCount
          nodes {
            createdBlockId
            investor: investorId
            stoId
            offeringToken
            raiseToken
            offeringTokenAmount
            raiseTokenAmount
            datetime
          }
        }
        fundings(filter: { assetId: { equalTo: "${tiker}" } }) {
          totalCount
          nodes {
            createdBlockId
            ticker: assetId
            fundingRound
            amount
            totalFundingAmount
          }
        }
      }
    `;

    const q = { query: query };

    try {
      const { data: response } = await apolloClient.query(q);
      console.log("ASSET ==> GraphQL response ==> ", response);
      console.log("Now get users from redux store ... ");

      // Weel beloq I ve tryed to Normalize data The Assets and users but seems this is not an easy
      // here is my promts history with GPT : https://chat.openai.com/share/75db32be-d0a7-4007-973f-a7d4c8dabb6e

      // The main idea is that its easier todo in UI side

      // const users = state.users.response;

      // const users = thunkAPI.getState().users.response as IUser[];
      // console.log("USERS ==> ", users.length); // if no users dipatch here ?

      // const usersWithCdd = users.filter((user) => user.cdd !== null);
      // console.log("USERS WITH CDD ==> ", usersWithCdd.length);

      // console.log("========= Asset Original ===========");
      // console.log(response);
      // console.log("====================================");

      // let _asset = response as AssetByTiker;

      // // .... Iterate Investments and set user to each investment (if found)
      // _asset.investments.nodes.forEach((investment: Investment) => {
      //   // investment.user = null; // Set default value null ...
      //   const user = usersWithCdd.find(
      //     (user) => user.cdd === investment.investor
      //   );
      //   console.log("Investor: ", investment.investor, "USER ==> ", user);

      //   if (user) {
      //     // investment["user"] = user;
      //     investment.userName = "null";
      //   }
      // });

      // Iterate asset holders and attach user Object to each holder
      // response.assets.nodes.forEach((asset: Asset) => {
      //   asset.holders.nodes.forEach((holder) => {
      //     const user = usersWithCdd.find((user) => user.cdd === holder.did);
      //     holder.user = user;
      //   });
      // });

      // console.log("========= Asset Modified ===========");
      // console.log(response);
      // console.log("====================================");

      return response;
    } catch (error) {
      console.log("ASSET ==> GraphQL Error  ==> ", error);
      return {};
    }
  }
);

export const assetSlice = createSlice({
  name: "asset",
  initialState,
  reducers: {
    mergeUsersAndTokens: (state, action) => {
      console.log("===> Merge Users And tokens ", action.payload);
      // const reduxStore = store.getState();
      // if (reduxStore.users.response.length === 0) {
      //   console.log("NO USERS IN STORE .... ");
      // }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(gqlAssetByTiker.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(gqlAssetByTiker.fulfilled, (state, { payload }) => {
      // console.log("ASSET ==> Fulfilled ==> GraphQL Data ==> ", payload);

      state.response = payload;
      state.loading = false;
    });
    builder.addCase(gqlAssetByTiker.rejected, (state, action) => {
      // console.log("ASSET ==> rejected => ", action);
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const selectAssetHolders = (state: any) =>
  state.response?.assetHolders.nodes;

export const assetSliceSelector = (state: any) => state.asset;
// export const { someActionFromThisFile } = tokensSlice.actions;
export default assetSlice.reducer;
