import { Avatar, Text, Group, Skeleton, Table } from "@mantine/core";
import { IconPhoneCall, IconAt } from "@tabler/icons-react";
import classes from "./Assets.module.css";
import { Asset } from "../../app/types/asset";
import { STO } from "../../app/types/assets";
import { formatDateLocale, formatNumber } from "../Helphers";

type Props = {
  stos: { nodes: STO[] } | undefined;
};

// "nodes": [
//       {
//         "id": "ANDI1/0",
//         "stoId": 0,
//         "offeringAsset": {
//           "ticker": "ANDI1"
//         },
//         "name": "Andi1",
//         "raisingAssetId": "USDT",
//         "status": "Live",
//         "start": "2023-07-30T14:00:00",
//         "end": "2024-02-29T06:30:00",
//         "tiers": [
//           {
//             "price": 1000000,
//             "total": 1000000000000,
//             "remaining": 1000000000000
//           }
//         ],
//         "minimumInvestment": "1000000",
//         "createdAt": "2023-07-31T06:57:42.879+00:00",
//         "createdBlockId": "9483380"
//       }
// ]

export function AssetStos({ stos }: Props) {
  const ths = (
    <Table.Tr>
      <Table.Th>ID</Table.Th>
      <Table.Th>StoID</Table.Th>
      <Table.Th>offeringAsset</Table.Th>
      <Table.Th>Name</Table.Th>
      <Table.Th>RaisingAssetId</Table.Th>
      <Table.Th>Status</Table.Th>
      <Table.Th>Start Date</Table.Th>
      <Table.Th>End Date</Table.Th>
      <Table.Th>Tiers</Table.Th>
      <Table.Th>Minimum Investment</Table.Th>
      <Table.Th>Date Created</Table.Th>
      <Table.Th>Block Number</Table.Th>
    </Table.Tr>
  );

  const rows = stos?.nodes.map((sto: STO, id: number) => (
    <Table.Tr key={id + 1}>
      <Table.Td>{sto.id}</Table.Td>
      <Table.Td>{sto.stoId}</Table.Td>
      <Table.Td>{sto.offeringAsset.ticker}</Table.Td>
      <Table.Td>{sto.name}</Table.Td>
      <Table.Td>{sto.raisingAssetId}</Table.Td>
      <Table.Td>{sto.status}</Table.Td>
      <Table.Td>{formatDateLocale(new Date(sto.start), "dd-MMM-y")}</Table.Td>
      <Table.Td>{formatDateLocale(new Date(sto.end), "dd-MMM-y")}</Table.Td>
      <Table.Td>
        {sto.tiers.map((tier, id) => (
          <Group gap="sm">
            <div>
              <Text fz="sm" fw={500}>
                {"Price: "} {formatNumber(tier.price / 1000000)}{" "}
                {sto.raisingAssetId}
              </Text>
              <Text fz="sm" fw={500}>
                {"Total: "} {formatNumber(tier.total / 1000000)}{" "}
                {/* {sto.raisingAssetId} */}
              </Text>
              <Text fz="sm" fw={500}>
                {"Remaining: "} {formatNumber(tier.remaining / 1000000)}{" "}
                {/* {sto.raisingAssetId} */}
              </Text>
              {/* <Text fz="sm">{"item.email"}</Text> */}
            </div>
          </Group>
        ))}
      </Table.Td>
      <Table.Td>
        {formatNumber(parseFloat(sto.minimumInvestment) / 1000000, 2)}{" "}
        {sto.raisingAssetId}
      </Table.Td>
      <Table.Td>
        {formatDateLocale(new Date(sto.createdAt), "dd-MMM-y")}
      </Table.Td>
      <Table.Td>{sto.createdBlockId}</Table.Td>
    </Table.Tr>
  ));

  return (
    <>
      <h2> STOS </h2>{" "}
      <Table highlightOnHover>
        <Table.Thead>{ths}</Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </>
  );
}
