import { useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
  postDistributionData,
  setActiveStep,
  nextStep,
  prevStep,
} from "../../app/index";
import { Stepper, Button, Group } from "@mantine/core";
import UsersToDistribute from "./Users";
import TokensToDistribute from "./Tokens";
import Distribute from "./Distribute";

export default function TokenDistribution() {
  // const context = useContext(Context);
  const dispatch = useAppDispatch();

  const distr = useAppSelector((state) => state.assetdistribution);

  // const [active, setActive] = useState(0);
  // const nextStep = () => {
  //   console.log("Axtive step : ", active);
  //   setActive((current) => (current < 3 ? current + 1 : current));
  // };

  // const prevStep = () => {
  //   setActive((current) => (current > 0 ? current - 1 : current));
  // };

  const postData = () => {
    dispatch(postDistributionData());
  };

  return (
    <>
      <Stepper
        active={distr.activeStep}
        onStepClick={(step) => dispatch(setActiveStep(step))}
        allowNextStepsSelect={false}
      >
        <Stepper.Step label="Select Asset" description="Choose Asset">
          <TokensToDistribute />
        </Stepper.Step>
        <Stepper.Step label="Select Users" description="Pickup accounts">
          <UsersToDistribute />
        </Stepper.Step>
        <Stepper.Step label="Distribute" description="Dsitribute Assets">
          <Distribute />
        </Stepper.Step>
        <Stepper.Completed>
          {" "}
          <h1> Asset distribution completed successfully </h1>{" "}
        </Stepper.Completed>
      </Stepper>

      {distr.activeStep === 2 && (
        <Group justify="center" mt="xl">
          <Button loading={distr.loading} variant="default" onClick={postData}>
            Start Distribution
          </Button>
        </Group>
      )}

      {/* {distr.activeStep === 3 && "Last step"} */}

      {distr.activeStep !== 2 && distr.activeStep !== 3 && (
        <Group justify="center" mt="xl">
          <Button variant="default" onClick={() => dispatch(prevStep())}>
            Back
          </Button>
          <Button onClick={() => dispatch(nextStep())}>Next step</Button>
        </Group>
      )}
    </>
  );
}
