import React, { useEffect } from "react";
import {
  ActionIcon,
  Box,
  Group,
  Loader,
  LoadingOverlay,
  NumberInput,
} from "@mantine/core";
import { Table } from "@mantine/core";
import { useAppDispatch, useAppSelector } from "../../app/index";
import {
  removeUser,
  updateTokenAmount,
} from "../../app/tokenDistributionSlice";
import { IconCheck, IconTrash } from "@tabler/icons-react";

export default function Distribute() {
  const distr = useAppSelector((state) => state.assetdistribution);
  const dispatch = useAppDispatch();
  const [value, setValue] = React.useState("");

  // useEffect(() => {}, [value]);

  const rows = distr.usersToDistribute.map((user) => (
    <Table.Tr key={user.userId}>
      <Table.Td>{user.userName}</Table.Td>
      <Table.Td>
        <NumberInput
          disabled={user.processing}
          width={100}
          placeholder="Enter amount"
          thousandSeparator=","
          defaultValue={0}
          value={user.tokenAmount}
          onChange={(value) => {
            dispatch(
              updateTokenAmount({
                userId: user.userId,
                newAmount: value,
              })
            );
          }}
        />
      </Table.Td>
      <Table.Td>
        <Group>
          {/* // @TODO DIX THIS */}
          {/* {user.distributionStatus?.success && ( */}
          <ActionIcon
            disabled={distr.loading}
            variant="outline"
            color="red"
            size="lg"
            aria-label="Settings"
            onClick={() => dispatch(removeUser({ userId: user.userId }))}
          >
            <IconTrash style={{ width: "70%", height: "70%" }} stroke={1.5} />
          </ActionIcon>
          {/* )} */}

          {user.processing && <Loader color="blue" size="sm" type="bars" />}
          {user.distributionStatus?.success ? <IconCheck /> : ""}
        </Group>
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <div>
      <Box pos="relative">
        {/* <LoadingOverlay
          visible={distr.loading}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
          loaderProps={{ color: "pink", type: "bars" }}
        /> */}
        <h1>Step 3: Distribute {distr.token} </h1>
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>#</Table.Th>
              <Table.Th>UserName</Table.Th>
              <Table.Th>Amount</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </Box>
    </div>
  );
}
