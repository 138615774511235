import { useContext, useEffect, useState } from "react";
import Context from "../../context/Context";
import { useAppDispatch, useAppSelector, fetchFunds } from "../../app/index";
import { Badge, Table, Text } from "@mantine/core";
import { formatDateLocale, formatNumber } from "../Helphers";
import ConformFundRequestButton from "./ConfirmFundRequestButton";

const REACT_APP_SUBSCAN_URL = process.env.REACT_APP_SUBSCAN_URL;

export default function FundsListing() {
  const context = useContext(Context);
  const dispatch = useAppDispatch();
  const [value, setValue] = useState("");

  const funds = useAppSelector((state) => state.funds);

  useEffect(() => {
    const { service } = context.globalstate;
    dispatch(fetchFunds(service));
  }, [value]);

  if (funds.loading) return <h1>Loading Funds ... </h1>;

  const ths = (
    <Table.Tr>
      <Table.Th>#</Table.Th>
      <Table.Th>User</Table.Th>
      <Table.Th>Op Type</Table.Th>
      <Table.Th>Amount</Table.Th>
      <Table.Th>Request Date</Table.Th>
      <Table.Th>Confirm Date</Table.Th>
      <Table.Th>Status/Action</Table.Th>
    </Table.Tr>
  );

  const rows = funds.response.data.map((fundrequest) => (
    <Table.Tr key={"id" + fundrequest.id}>
      <Table.Td>
        <Text fw={600}>{fundrequest.id} </Text>{" "}
      </Table.Td>
      <Table.Td>
        <Text fw={600}>{fundrequest.username} </Text>
      </Table.Td>
      <Table.Td>
        {fundrequest.transaction_type === 1 ? (
          <Badge color="green">Deposit</Badge>
        ) : (
          <Badge color="yellow">Witdrawal</Badge>
        )}
      </Table.Td>
      <Table.Td>
        <Text fw={600}>{formatNumber(fundrequest.amount)}</Text>
      </Table.Td>
      <Table.Td>
        {" "}
        <Text fw={600}>
          {" "}
          {formatDateLocale(new Date(fundrequest.created_at))}
        </Text>
      </Table.Td>
      <Table.Td>
        <Text fw={600}>
          {fundrequest.updated_at &&
            formatDateLocale(new Date(fundrequest.updated_at))}
        </Text>
      </Table.Td>
      <Table.Td>
        <ConformFundRequestButton {...fundrequest} />
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <>
      <h1>Funds</h1>
      <Table highlightOnHover>
        <Table.Thead>{ths}</Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </>
  );
}
