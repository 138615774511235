import React, { useState, useEffect } from "react";
import { Checkbox, Table } from "@mantine/core";
import Context from "../../context/Context";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { IUser } from "../../app/index";
import { addUser, removeUser } from "../../app/tokenDistributionSlice";
import { User } from "../../app/types/distributions";

export default function UsersToDistribute() {
  const context = React.useContext(Context);
  const dispatch = useAppDispatch();
  const [value, setValue] = useState("");
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  // Selecting the distribution slice and users  need for set if checked
  const usersToDistribute = useAppSelector(
    (state) => state.assetdistribution.usersToDistribute
  );
  const users = useAppSelector((state) => state.users);

  const selectUser = (
    event: React.ChangeEvent<HTMLInputElement>,
    user: IUser
  ) => {
    const _user: User = {
      processing: false,
      userId: user.id,
      userName: user.username,
      tokenAmount: 0,
    };

    if (event.target.checked) {
      console.log("User Selected : ", user.id, " - ", user.username);
      dispatch(addUser(_user));
    } else {
      console.log("User Removed : ", user.id, " - ", user.username);
      dispatch(removeUser(_user));
    }

    setSelectedRows(
      event.target.checked
        ? [...selectedRows, user.id]
        : selectedRows.filter((id) => id !== user.id)
    );
  };

  useEffect(() => {
    // console.log("Users in distribution module ... ");
  }, [value]);

  if (users.loading) {
    return (
      <div className="container-fluid">
        <div> Loading users ... </div>
      </div>
    );
  }

  const renderRows = () => {
    return users.response?.map((user) => (
      <Table.Tr
        key={user.username}
        bg={
          selectedRows.includes(user.id)
            ? "var(--mantine-color-blue-light)"
            : undefined
        }
      >
        <Table.Td>
          <Checkbox
            aria-label="Select row"
            checked={usersToDistribute.some((u) => u.userId === user.id)}
            onChange={(event) => selectUser(event, user)}
          />
        </Table.Td>
        <Table.Td> {user.username} </Table.Td>
        <Table.Td> {user.role} </Table.Td>
      </Table.Tr>
    ));
  };

  const rows = renderRows();

  return (
    <div>
      <h1>Step 2: Select Accounts </h1>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>#</Table.Th>
            <Table.Th>UserName</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </div>
  );
}
