import { useContext, useEffect, useState } from "react";
import { Tooltip, UnstyledButton, Stack, rem, AppShell } from "@mantine/core";
import {
  IconHome2,
  IconLogout,
  IconArrowsTransferUp,
  IconShieldCheck,
  IconTransformFilled,
  IconCoin,
} from "@tabler/icons-react";
import classes from "./NavbarMinimal.module.css";
import {
  redirect,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Context from "../../context/Context";

interface NavbarLinkProps {
  icon: typeof IconHome2;
  label: string;
  active?: boolean;
  onClick?(): void;
}

function NavbarLink({ icon: Icon, label, active, onClick }: NavbarLinkProps) {
  return (
    <Tooltip label={label} position="right" transitionProps={{ duration: 0 }}>
      <UnstyledButton
        onClick={onClick}
        className={classes.link}
        data-active={active || undefined}
      >
        <Icon style={{ width: rem(20), height: rem(20) }} stroke={1.5} />
      </UnstyledButton>
    </Tooltip>
  );
}

const linksData = [
  { icon: IconHome2, label: "Home", path: "/" },
  { icon: IconArrowsTransferUp, label: "Funds", path: "/Funds" },
  { icon: IconShieldCheck, path: "/tokens", label: "Security Tokens" },
  { icon: IconTransformFilled, path: "/exchange", label: "Exchange" },
  {
    icon: IconCoin,
    path: "/distribution",
    label: "Tokens Distribution",
  },
  // { icon: IconBroadcast, path: "/test", label: "Test Page" },
  // { icon: IconDeviceDesktopAnalytics, label: "Analytics" },
  // { icon: IconCalendarStats, label: "Releases" },
  // { icon: IconUser, label: "Account" },
  // { icon: IconFingerprint, label: "Security" },
  // { icon: IconSettings, label: "Settings" },
];

export function NavbarMinimal() {
  const [active, setActive] = useState<string>("Home");
  const context = useContext(Context);
  const location = useLocation();
  const params = useParams();
  let navigate = useNavigate();

  function logout() {
    context.handlers.logout();
  }

  const links = linksData.map((link, index) => (
    <NavbarLink
      {...link}
      key={link.label}
      active={link.path === location.pathname}
      onClick={() => navigate(link.path)}
    />
  ));

  useEffect(() => {
    setActive(location.pathname);
  }, []);

  return (
    <AppShell.Navbar>
      <nav className={classes.navbar}>
        <div className={classes.navbarMain}>
          <Stack justify="center" gap={0}>
            {links}
          </Stack>
        </div>

        <Stack justify="center" gap={0}>
          {/* <NavbarLink icon={IconSwitchHorizontal} label="Change account" /> */}
          <NavbarLink onClick={logout} icon={IconLogout} label="Logout" />
        </Stack>
      </nav>
    </AppShell.Navbar>
  );
}
