// this is depricated part >????

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { io, Socket } from "socket.io-client";

interface WebSocketState {
  connected: boolean;
}

const initialState: WebSocketState = {
  connected: false,
};

// export const initSocket = createAsyncThunk(
//   "websocket/initializeSocket",
//   async () => {
//     const socket = io("http://localhost:5000", {
//       transports: ["websocket"],
//     });
//     return socket;
//   }
// );

export const websocketSlice = createSlice({
  name: "websocket",
  initialState,
  reducers: {
    websocketConnected: (state) => {
      state.connected = true;
    },
    websocketDisconnected: (state) => {
      state.connected = false;
    },
  },
});

export const { websocketConnected, websocketDisconnected } =
  websocketSlice.actions;

export const selectWebSocket = (state: any) => state.websocket;

export default websocketSlice.reducer;
