// websocketListener.ts
import { socket } from "../WebsocketClient";
import { store } from "./store";
import { updateUserDistributionStatus } from "./tokenDistributionSlice"; // import the new action
import { ProcessTransactionResponse } from "./types/distributions";

const setupWebSocketListeners = () => {
  socket.on("connect", () => {
    console.log("WebSocket connected");
  });

  socket.on("disconnect", () => {
    console.log("WebSocket disconnected");
  });

  socket.on("distribution", (response: any) => {
    console.log("Received distribution event");
    console.log("response: ", response);
    const { success, userId, errorCode } = response;
    console.log("success: ", success, "userId: ", userId);
    console.log("*--------------------------------------");

    store.dispatch(
      updateUserDistributionStatus({ success, userId, errorCode })
    );
  });

  socket.on("error", (error) => {
    console.error("WebSocket error:", error);
  });
};

export default setupWebSocketListeners;
