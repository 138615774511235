import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { ITransactionResponse } from "./types";

const API_URL = process.env.REACT_APP_API_URL;

interface ITransactionsState {
  response: ITransactionResponse;
  loading: boolean;
  error: object;
}

const initialState: ITransactionsState = {
  response: { success: false, data: [] },
  loading: true,
  error: {},
};

// export const fetchPosts = createAsyncThunk("posts/fetchPosts", async () => {
//   const response = await axios.get(BASE_URL);
//   console.log(response.data);
//   return response?.data;
// });

export const fetchFunds = createAsyncThunk(
  "admin/funds",
  async (service: AxiosResponse) => {
    const uri = `${API_URL}/admin/transactions`;
    try {
      console.log("Funds Start Loading ... ");
      const { data: response } = await service.request.get(uri);
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
);

export const fundsSlice = createSlice({
  name: "funds",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFunds.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchFunds.fulfilled, (state, { payload }) => {
      state.response = payload;
      state.loading = false;
    });
    builder.addCase(fetchFunds.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const fundsStateSelector = (state: any) => state.funds;
export default fundsSlice.reducer;
