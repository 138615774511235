import { Locale, format, setDefaultOptions } from "date-fns";
import { enUS, enAU, enGB } from "date-fns/locale";

// setDefaultOptions({ locale: enUS });

export function truncateText(text: string, n: number): string {
  return text.substring(0, n) + "..." + text.substring(text.length - n);
}

export function formatNumber(
  value: number,
  minimumFractionDigits: number = 2,
  maximumFractionDigits: number = 2,
  locale: string = "en-US"
): string {
  const formatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits,
    useGrouping: true, // Enables thousand separators
  });

  return formatter.format(value);
}

export function formatDateLocale(date: Date, _format?: string) {
  const userLocaleString = Intl.DateTimeFormat().resolvedOptions().locale;
  // console.log("userLocaleString: ", userLocaleString);

  const availableLocales: Record<string, Locale> = {
    "en-US": enUS,
    "en-GB": enGB,
    "en-AU": enAU,
  }; // Add other imported locales to this object

  // Get the date-fns Locale object based on the user's locale string
  const userLocale = availableLocales[userLocaleString] || enUS;

  // console.log("userLocale Objec :", userLocale, "====", userLocale.code);

  let formatStr = _format || "dd/MM/y";
  // console.log("formatStr: ", formatStr);
  // console.log("date: ", date);
  const result = format(new Date(date), formatStr, {
    locale: userLocale,
  });

  return result;
}
