import { Anchor, Avatar, Badge, Group, Table, Text } from "@mantine/core";
import { Link } from "react-router-dom";
import { formatDateLocale, formatNumber } from "../Helphers";
import { Asset } from "../../app/types/asset";

const REACT_APP_SUBSCAN_URL = process.env.REACT_APP_SUBSCAN_URL;

type AssetProps = {
  id: number;
  asset: Asset;
};

const fontSize = 400;

export function TokenListItem({ asset, id }: AssetProps) {
  return (
    <Table.Tr key={`id_${asset.ticker}`}>
      <Table.Td> {id} </Table.Td>
      <Table.Td>
        <Group gap="sm">
          <Avatar size={40} radius={40} />
          <div>
            <Link to={`/token/${asset.ticker}`}>
              <Text fz="sm" fw={500}>
                {asset.ticker}
              </Text>
            </Link>
            <Text c="dimmed" fz="xs">
              {asset.name}
            </Text>
          </div>
        </Group>
      </Table.Td>
      {/* <Table.Td>
        <Text fw={fontSize}>{token.name} </Text>
      </Table.Td> */}
      <Table.Td>
        <Text fw={fontSize}>{asset.fundingRound} </Text>
      </Table.Td>
      <Table.Td>
        <Badge
          variant="outline"
          color={asset.isFrozen ? "red" : "teal"}
          radius="lg"
        >
          {asset.isFrozen ? "Frozen" : "Active"}
        </Badge>
      </Table.Td>
      <Table.Td>
        <Badge
          variant="outline"
          color={asset.isDivisible ? "teal" : "red"}
          radius="lg"
        >
          {asset.isDivisible ? "Divisible" : "Indivisible"}
        </Badge>
      </Table.Td>
      {/* <Table.Td align="center">
        <Text fw={fontSize}>{asset.isUniquenessRequired ? "Yes" : "No"} </Text>
      </Table.Td> */}
      <Table.Td align="center">
        <Text fw={fontSize}>{asset.totalTransfers} </Text>
      </Table.Td>
      <Table.Td>
        <Text fw={fontSize + 400}>
          {formatNumber(Number(asset.totalSupply) / 1000000, 0)}{" "}
        </Text>
      </Table.Td>
      <Table.Td>
        <Badge
          variant="outline"
          color={asset.isCompliancePaused ? "red" : "teal"}
          radius="lg"
        >
          {asset.isCompliancePaused ? "Paused" : "Active"}
        </Badge>
      </Table.Td>
      <Table.Td>
        <Text fw={fontSize}>
          {
            <Anchor
              href={`${REACT_APP_SUBSCAN_URL}block/${asset.createdBlockId}`}
              target="_blank"
              underline="never"
            >
              {asset.createdBlockId}
            </Anchor>
          }{" "}
        </Text>
      </Table.Td>
      <Table.Td>
        <Text fw={600}>{formatDateLocale(new Date(asset.createdAt))} </Text>
      </Table.Td>
    </Table.Tr>
  );
}
