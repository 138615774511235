import { useContext, useEffect, useState } from "react";
import Context from "../../context/Context";
import { useAppDispatch, useAppSelector, ITransaction } from "../../app/index";
import { Button, Group, Tooltip } from "@mantine/core";
import {
  IconArrowsTransferUp,
  IconCheckbox,
  IconChecks,
} from "@tabler/icons-react";
import { socket } from "../../WebsocketClient";
import { Notification, rem, Text } from "@mantine/core";

interface Props extends ITransaction {}

interface MessageFormat {
  success: boolean;
  transactionId: number;
  errorcode: string;
}

export default function ConformFundRequestButton(props: Props) {
  const context = useContext(Context);
  const dispatch = useAppDispatch();
  const [value, setValue] = useState("");
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [proccesing, setProccesing] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  /**
   * 1. Send a message to the server, via websocket ...
   */
  async function conformTransactionRequest() {
    setProccesing(true);
    console.log("Confirm trabsaction request ::: ", props);
    const message = JSON.stringify({
      transactionId: props.id,
      transactionType: props.transaction_type, // 1 Deposit 2 Withdraw
    });
    socket.emit("job", message);
  }

  useEffect(() => {
    setConfirmed(props.confirmed);
    /**
     * 2. We listen for a response from the server, via websocket ...
     */
    socket.on("job", (response) => {
      console.log("Response from server ::: ", response);
      let socketResponse = response as MessageFormat;

      // const mockError = {
      //   success: true,
      //   transactionId: 79,
      //   errorcode: "OK",
      // };
      // socketResponse = mockError; // mocking a response

      // As we isoloated Button Component for each transaction each button component is listenining for a response
      // So we need to check if the response is for this transaction
      if (socketResponse.transactionId === props.id) {
        // console.log("Response from server ::: ", socketResponse);
        if (socketResponse.success) {
          setConfirmed(true);
        } else {
          setError(true);
          setErrorText(socketResponse.errorcode);
        }
        setProccesing(false);
      }
    });
  }, [value]);

  if (confirmed) {
    return (
      <>
        <Group justify="center" gap="xs" grow>
          <Tooltip label="Transaction Confirmed">
            <IconCheckbox
              style={{ width: rem(30), height: rem(30) }}
              stroke={1.5}
              color="var(--mantine-color-green-filled)"
            />
          </Tooltip>
        </Group>
      </>
    );
  }

  return (
    <>
      <Group justify="center" gap="xs" grow>
        <Button
          variant="default"
          onClick={() => conformTransactionRequest()}
          loading={proccesing}
          rightSection={<IconArrowsTransferUp size={14} />}
        >
          Confirm
        </Button>
        {error && <Text c="red">{errorText}</Text>}
      </Group>
    </>
  );
}
