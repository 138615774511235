import React, { useState, useEffect, useContext } from "react";
import io from "socket.io-client";
import Context from "../../context/Context";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

import {
  websocketConnected,
  websocketDisconnected,
} from "../../app/websocketSlice";

import { connectToSocket, disconnectFromSocket } from "../../app/socketSlice";

import { Box, Button, Group, Indicator, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDispatch } from "react-redux";

function PlayGround() {
  const context = React.useContext(Context);
  const users = useAppSelector((state) => state.users);
  const dispatch = useDispatch();
  const websocket = useAppSelector((state) => state.websocket);
  const [value, setValue] = useState(0);

  const form = useForm({
    initialValues: {
      message: "",
    },
  });

  const socket = io("http://localhost:5000", {
    transports: ["websocket"],
    // transportOptions: {
    //   polling: {
    //     extraHeaders: {
    //       Authorization: "Bearer abc",
    //     },
    //   },
    // },
  });

  useEffect(() => {
    console.log("Webscoket Playground ...");

    // socket.on("connect", () => {
    //   dispatch(websocketConnected());
    // });

    socket.on("message", (message) => {
      console.log("Message From Server : ", message);
    });

    // socket.on("disconnect", () => {
    //   dispatch(websocketDisconnected());
    // });
  }, [value]);

  function sendMessage(room: string) {
    console.log("Send Message : ", form.values.message);
    socket.emit("message", { room: room }, form.values.message);
  }

  function myEvent() {
    console.log("My Event : ", form.values.message);
    socket.emit("event1", "this is custom event ...");
  }

  socket.on("message", (message) => {
    console.log("Message From Server : ", message);
  });

  async function disconnect() {}
  async function joinRoom(room: string) {
    socket.emit("join", { room: room });
  }

  return <h3>Testing page , will be removed soon </h3>;

  return (
    <>
      <Box maw={400} mx="auto">
        <Button fullWidth onClick={() => joinRoom("room1")}>
          Join Room ...{"Room1"}
        </Button>{" "}
        <br />
        <Button fullWidth color="black" onClick={() => joinRoom("room2")}>
          Join Room ...{"Room2"}
        </Button>
        <br />
        <Button fullWidth color="red" onClick={myEvent}>
          Custom Event ...{" "}
        </Button>
        <br />
        <hr />
        <br />
        <div>
          Websocket status: {websocket.connected ? "Conected" : "Disconnected"}
        </div>
        <TextInput
          mt="md"
          label="Message"
          placeholder="Type message ... "
          {...form.getInputProps("message")}
        />
        <Group grow mt="xl">
          <Button variant="outline" onClick={() => sendMessage("room2")}>
            Send message to server
          </Button>
        </Group>
      </Box>
    </>
  );
}

export default PlayGround;
