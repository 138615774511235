import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { IUser } from "./types/user";

const API_URL = process.env.REACT_APP_API_URL;

interface IUsersState {
  response: IUser[];
  loading: boolean;
  error: object;
}

const initialState: IUsersState = {
  response: [],
  loading: true,
  error: {},
};

export const fetchUsers = createAsyncThunk(
  "admin/users",
  async (service: AxiosResponse) => {
    const uri = `${API_URL}/admin/users/getallusers`;
    try {
      const { data: response } = await service.request.get(uri);
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUsers.fulfilled, (state, { payload }) => {
      state.response = payload;
      state.loading = false;
    });
    builder.addCase(fetchUsers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const selectUserByID = (DID: string) => {
  return (state: IUsersState): IUser | undefined => {
    return state.response.find((user: IUser) => user.cdd === DID);
  };
};

export const usersStateSelector = (state: any) => state.users;
export default usersSlice.reducer;
