import io from "socket.io-client";

const WS_ENDPOINT = process.env.REACT_APP_WEBSOCKET_URL;

if (!WS_ENDPOINT) {
  throw new Error("WEBSOCKET_URL is not defined");
}

export const socket = io(WS_ENDPOINT, {
  transports: ["websocket"],
});

// class SocketClient {
//   socket: any = null;

//   constructor() {
//     console.log("SocketClient constructor ... ");
//   }

//   connect(): Promise<void> {
//     this.socket = io("http://localhost:5000", {
//       transports: ["websocket"],
//     });

//     return new Promise<void>((resolve, reject) => {
//       this.socket.on("connect", () => resolve());
//       this.socket.on("connect_error", (error: any) => reject(error));
//     });
//   }

//   disconnect(): Promise<void> {
//     return new Promise<void>((resolve) => {
//       this.socket.disconnect(() => {
//         this.socket = null;
//         resolve();
//       });
//     });
//   }

//   emit(event: string, data: any): Promise<void> {
//     return new Promise<void>((resolve, reject) => {
//       if (!this.socket) return reject("No socket connection.");

//       return this.socket.emit(event, data, (response: any) => {
//         // Response is the optional callback that you can use with socket.io in every request. See 1 above.
//         if (response.error) {
//           console.error(response.error);
//           return reject(response.error);
//         }

//         return resolve();
//       });
//     });
//   }

//   on(event: string, fun: (...args: any[]) => void): Promise<void> {
//     // No promise is needed here, but we're expecting one in the middleware.
//     return new Promise<void>((resolve, reject) => {
//       if (!this.socket) return reject("No socket connection.");

//       this.socket.on(event, fun);
//       resolve();
//     });
//   }
// }

// export default SocketClient;
