import { useContext, useEffect, useState } from "react";
import Context from "../../context/Context";
import { useAppDispatch, useAppSelector, fetchFunds } from "../../app/index";
import FundsListing from "./FundsListing";

export default function Funds() {
  const context = useContext(Context);
  const dispatch = useAppDispatch();
  const [value, setValue] = useState("");

  const funds = useAppSelector((state) => state.funds);

  useEffect(() => {
    const { service } = context.globalstate;
    dispatch(fetchFunds(service));
  }, [value]);

  return <FundsListing />;
}
