import { useContext, useState, useEffect } from "react";
import { Avatar, Button, Group, Skeleton, Table, Text } from "@mantine/core";
import Context from "../../context/Context";
import { useAppDispatch, useAppSelector, gqlTokens } from "../../app/index";
import { resetDistribution, setToken } from "../../app/tokenDistributionSlice";
import { Link } from "react-router-dom";
import { IconArrowRight, IconCheck } from "@tabler/icons-react";

export default function TokensToDistribute() {
  const context = useContext(Context);
  const dispatch = useAppDispatch();
  const [value, setValue] = useState("");
  const [selectedTicker, setSelectedTicker] = useState("");

  const tokens = useAppSelector((state) => state.tokens);
  const distr = useAppSelector((state) => state.assetdistribution);

  useEffect(() => {
    console.log("... TokensToDistribute useEffect - Tokens ... ");
    dispatch(resetDistribution());
    dispatch(gqlTokens());
  }, [value]);

  if (tokens.loading)
    return (
      <div>
        {Array.from({ length: window.innerHeight / 45 }, (_, index) => (
          <Skeleton key={index} height={20} mt={10} width="95%" radius="sm" />
        ))}
      </div>
    );

  const handleSelectToken = (ticker: string) => {
    dispatch(setToken(ticker));
    setSelectedTicker(ticker);
  };

  const ths = (
    <Table.Tr>
      <Table.Th>#</Table.Th>
      <Table.Th>Asset</Table.Th>
      <Table.Th></Table.Th>
    </Table.Tr>
  );

  const rows = tokens.response?.assets.nodes.map((asset, id) => (
    <Table.Tr key={`id_${asset.ticker}`}>
      <Table.Td> {id + 1} </Table.Td>
      <Table.Td>
        <Group gap="sm">
          {/* <Avatar size={40} radius={40} /> */}
          <div>
            <Link to={`/token/${asset.ticker}`}>
              <Text fz="sm" fw={500}>
                {asset.ticker}
              </Text>
            </Link>
            <Text c="dimmed" fz="xs">
              {asset.name}
            </Text>
            {/* {selectedTicker === asset.ticker && (
              <Text>{selectedTicker === asset.ticker ? "SELECTED" : ""}</Text>
            )} */}
          </div>
        </Group>
      </Table.Td>
      <Table.Td>
        <Button
          variant="filled"
          color={selectedTicker === asset.ticker ? "teal" : "gray"}
          onClick={() => handleSelectToken(asset.ticker)}
          rightSection={
            selectedTicker === asset.ticker ? (
              <IconCheck size={14} />
            ) : (
              <IconArrowRight size={14} />
            )
          }
        >
          {selectedTicker === asset.ticker ? "Selected" : "Select"}
        </Button>
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <div>
      {/* <Highlight highlight={distr.token}>{distr.token}</Highlight> */}
      <h1>
        Step-1: Select Token{" "}
        <span className={"highlightedText"}> {distr.token} </span>
      </h1>

      <Table highlightOnHover>
        <Table.Thead>{ths}</Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </div>
  );
}
