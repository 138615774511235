import { useContext, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  ActionIcon,
  Badge,
  Button,
  Center,
  Group,
  Switch,
  Table,
  Text,
  Tooltip,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import Context from "../../context/Context";
import { useNavigate, useParams } from "react-router-dom";
import { IUser, fetchUserDetailsFromGraph } from "../../app/index";
import { formatDateLocale, formatNumber } from "../Helphers";
import _ from "lodash";
import { IconCornerUpLeft } from "@tabler/icons-react";

const API_URL = process.env.REACT_APP_API_URL;

/**
 * Users details
 *
 */

export default function UserDetails() {
  const navigate = useNavigate();

  const context = useContext(Context);
  const dispatch = useAppDispatch();
  const params = useParams();
  const allusers = useAppSelector((state) => state.users);
  const userdetailsGraphData = useAppSelector((state) => state.userdetails);
  const [value, setValue] = useState("");
  const [user, setUser] = useState<IUser>();
  const [userIsAdmin, setUserIsAdmin] = useState<boolean>(false);

  // Need for passing parameters to the users page to refresh (re-fetch) the userlist
  const [userUpdated, setUserUpdated] = useState(false);

  interface IChangeUserRoleRequest {
    userId: number;
    role: "user" | "admin";
  }

  interface IChangeUserRoleResponse {
    statuscode: boolean;
    message: string;
  }

  /**
   * Updates user in the database with new role
   * @param user
   * @returns IChangeUserRoleResponse
   *
   */

  async function updateUserRole(user: IUser): Promise<IChangeUserRoleResponse> {
    const { service } = context.globalstate;
    const newRole: "user" | "admin" = user.role === "admin" ? "user" : "admin";

    const values: IChangeUserRoleRequest = {
      userId: user.id,
      role: newRole,
    };

    const { status: httpStatusCode, data } = await service.request.put(
      `${API_URL}/admin/users/setrole`,
      values
    );

    console.log("----------------------------------");
    console.log("Set Role Response: -> httpStatusCode:  ", httpStatusCode);
    console.log("Set Role Response -> Data :  ", data);

    switch (httpStatusCode) {
      case 200: {
        console.log("Status Updated Successfully");
        setUserIsAdmin(newRole === "admin" ? true : false);
        return {
          statuscode: true,
          message: "Status Updated Successfully",
        };
      }
      default: {
        console.log(
          "Something went wrong. Please try again later, or contact our administrator for further assistance."
        );
        return {
          statuscode: false,
          message:
            "Something went wrong. Please try again later, or contact our administrator for further assistance.",
        };
      }
    }
  }

  /**
   * Confromation window for changing user role
   *  @returns void
   *
   */

  const openConformationWindWindow = () =>
    modals.openConfirmModal({
      title: "Please confirm your action",
      children: (
        <Text size="sm">
          You are going to change the role of this user. Are you sure you want ?
        </Text>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: async () => {
        console.log("Confirm clicked , start update status !!!");
        const updateStatus = await updateUserRole(user as IUser);

        console.log("updateStatus: ", updateStatus);

        setUserUpdated(updateStatus.statuscode); // we uopdated user details , need refresh

        notifications.show({
          color: updateStatus.statuscode ? "green" : "red",
          title: updateStatus.statuscode ? "Success" : "Error",
          message: updateStatus.message,
        });
      },
    });

  useEffect(() => {
    const id = parseInt(params.userid || "0");
    const selectedUser = _.find(allusers.response, ["id", id]);
    if (selectedUser) {
      console.log("Selected User :", selectedUser);
      setUser(selectedUser);
      setUserIsAdmin(selectedUser.role === "admin" ? true : false);
      dispatch(fetchUserDetailsFromGraph({ userDid: selectedUser?.cdd || "" }));
    }
  }, [value]);

  if (!user)
    return (
      <Center>
        {" "}
        <h2> User Decentralized ID (DID) has not yet been generated. </h2>{" "}
      </Center>
    );

  if (userdetailsGraphData.loading) return <p>Loading ...</p>;

  const rows = (
    <>
      <Table.Tr key={"0"}>
        <Table.Td colSpan={2}>
          <Group gap={"xl"}>
            <div>
              <span>{"User Name: "}</span>
              <span>{user?.username}</span>
            </div>
            <div>
              <span>{"User ID: "}</span>
              <Badge>{user?.id}</Badge>{" "}
            </div>
            <div>
              <Tooltip label="Swicth to change role" refProp="rootRef">
                <Switch
                  checked={userIsAdmin}
                  onClick={openConformationWindWindow}
                  label={userIsAdmin ? "Admin" : "Investor"}
                />
              </Tooltip>
            </div>
          </Group>
        </Table.Td>
      </Table.Tr>
      <Table.Tr key={"1"}>
        <Table.Td>{"DID: "}</Table.Td>
        <Table.Td>{user?.cdd}</Table.Td>
      </Table.Tr>
      <Table.Tr key={"2"}>
        <Table.Td>{"Address: "}</Table.Td>
        <Table.Td>{user?.address}</Table.Td>
      </Table.Tr>
      <Table.Tr key={"3"}>
        <Table.Td>{"Polyx Balance: "}</Table.Td>
        <Table.Td>{formatNumber(user.polyxBalance, 2)}</Table.Td>
      </Table.Tr>
      <Table.Tr key={"4"}>
        <Table.Td>{"Maei Balance: "}</Table.Td>
        <Table.Td>{formatNumber(user.maeiBalance, 2)}</Table.Td>
      </Table.Tr>
    </>
  );

  const assetRows = userdetailsGraphData.response?.assetHolders?.nodes.map(
    (asset, key) => (
      <Table.Tr key={"asset" + key}>
        <Table.Td>{key + 1}</Table.Td>
        {/* <Table.Td>{asset.id}</Table.Td> */}
        <Table.Td>{asset.assetId}</Table.Td>
        <Table.Td>{formatNumber(parseFloat(asset.amount) / 1000000)}</Table.Td>
      </Table.Tr>
    )
  );

  //  {
  //           "nodeId": "WyJpbnZlc3RtZW50cyIsIjMyMDQ0NzgvNyJd",
  //           "id": "3204478/7",
  //           "investorId": "0x3e084c61efc7d57a9aee9372baf96e8f98f1fe716593966466464c3658d3007b",
  //           "stoId": 1,
  //           "offeringToken": "TEST-TOKEN-3",
  //           "raiseToken": "USDT",
  //           "offeringTokenAmount": "12000000",
  //           "raiseTokenAmount": "12000000",
  //           "datetime": "2022-05-19T18:00:12",
  //           "createdBlockId": "3204478",
  //           "updatedBlockId": "3204478",
  //           "createdAt": "2023-12-01T02:08:12.121+00:00",
  //           "updatedAt": "2023-12-01T02:08:12.121+00:00"
  // ,

  const investmentRows = userdetailsGraphData.response?.investments?.nodes.map(
    (investment, key) => (
      <Table.Tr key={"investment" + key}>
        <Table.Td>{key + 1}</Table.Td>
        <Table.Td>{investment.id}</Table.Td>
        <Table.Td>
          {formatDateLocale(new Date(investment.datetime), "dd/MMM/yyyy")}
        </Table.Td>
        <Table.Td>
          {" "}
          {formatNumber(
            parseFloat(investment.offeringTokenAmount) / 1000000
          )}{" "}
          <Text fw={500}>{investment.offeringToken}</Text>
        </Table.Td>
        <Table.Td>
          {" "}
          {formatNumber(parseFloat(investment.raiseTokenAmount) / 1000000)}{" "}
          <Text fw={500}>{investment.raiseToken}</Text>
        </Table.Td>
      </Table.Tr>
    )
  );

  return (
    <>
      <Group gap="lg">
        <ActionIcon
          size="xl"
          variant="white"
          radius="sm"
          aria-label="Settings"
          onClick={() => navigate("/", { state: { needrefresh: userUpdated } })}
        >
          <IconCornerUpLeft
            style={{ width: "70%", height: "70%" }}
            stroke={1.5}
          />
        </ActionIcon>
        {/* <Button variant="outline">
          <IconCornerUpLeft size={14} onClick={() => navigate(-1)} />
        </Button> */}
        <h2>User Details </h2>
      </Group>

      <Table>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>

      <h2>Investments</h2>

      <Table highlightOnHover>
        <Table.Tr>
          <Table.Th>#</Table.Th>
          <Table.Th>block./tx-id</Table.Th>
          <Table.Th>Amount</Table.Th>
        </Table.Tr>
        <Table.Tbody>{investmentRows}</Table.Tbody>
      </Table>

      <h2>Holding Assets </h2>

      <Table highlightOnHover>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>#</Table.Th>
            {/* <Table.Th>ID</Table.Th> */}
            <Table.Th>Asset</Table.Th>
            <Table.Th>Amount</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{assetRows}</Table.Tbody>
      </Table>
    </>
  );
}
