import { Avatar, Text, Group, Skeleton, Table } from "@mantine/core";
import { IconPhoneCall, IconAt } from "@tabler/icons-react";
import classes from "./Assets.module.css";
import { Asset } from "../../app/types/asset";
import { Funding } from "../../app/types/assets";
import { formatNumber } from "../Helphers";

type Props = {
  fundings:
    | {
        totalCount: number;
        nodes: Funding[];
      }
    | undefined;
};

// "fundings": {
//     "totalCount": 2,
//     "nodes": [
//       {
//         "createdBlockId": "9483379",
//         "ticker": "ANDI1",
//         "fundingRound": "Convertible Note",
//         "amount": "1000000000000",
//         "totalFundingAmount": "1000000000000"
//       },
//   }

export function AssetFundings({ fundings }: Props) {
  const ths = (
    <Table.Tr>
      <Table.Th>#</Table.Th>
      <Table.Th>Funding Round</Table.Th>
      <Table.Th>Amount</Table.Th>
      <Table.Th>Raise Token</Table.Th>
      <Table.Th>Total Funding Amount</Table.Th>
    </Table.Tr>
  );

  const rows = fundings?.nodes.map((funding: Funding, id: number) => (
    <Table.Tr key={id + 1}>
      <Table.Td>{id + 1}</Table.Td>
      <Table.Td>{funding.fundingRound}</Table.Td>
      <Table.Td>{formatNumber(parseFloat(funding.amount) / 1000000)}</Table.Td>
      <Table.Td>{funding.ticker}</Table.Td>
      <Table.Td>
        {formatNumber(parseFloat(funding.totalFundingAmount) / 1000000)}
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <>
      <h2>Fundings: {fundings?.totalCount} </h2>{" "}
      <Table highlightOnHover>
        <Table.Thead>{ths}</Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </>
  );
}
